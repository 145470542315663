export function injectMathJax() {
  if (!window.MathJax) {
    const script = document.createElement('script')
    script.src =
      'https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-chtml.js'
    script.async = true
    document.head.appendChild(script)
  }
}

export function initMathJax(options = {}, callback) {
  injectMathJax()
  // The default global configuration
  const defaultConfig = {
    tex: {
      inlineMath: [['$', '$']],
      displayMath: [['$$', '$$']],
      processEnvironments: true,
      processRefs: true,
    },
    options: {
      skipHtmlTags: ['noscript', 'style', 'textarea', 'pre', 'code'],
      ignoreHtmlClass: 'tex2jax_ignore',
    },
    startup: {
      pageReady: () => {
        callback && callback()
      },
    },
    svg: {
      fontCache: 'global',
    },
  }
  // Merge configuration
  const mergeConfig = Object.assign({}, defaultConfig, options)
  window.MathJax = mergeConfig
}

// assume array of ref contains
export const renderMathMl = (arrayOfRef) => {
  const MathJax = window.MathJax
  if (MathJax) {
    arrayOfRef.forEach(ref => {
      const mathHtml = ref.getElementsByTagName('math')
      const mjx = ref.getElementsByTagName('mjx-container')
      if (!mjx.length) {
        mathHtml.forEach(math => {
          ref.appendChild(MathJax.mathml2chtml(math.outerHTML))
          MathJax.startup.document.clear()
          MathJax.startup.document.updateDocument()
          math.innerHTML = ''
        })
      }
    })
  }
}
