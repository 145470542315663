import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    provinces: [],
    cities: [],
    districts: [],
    villages: [],
    provincesRegistration: [],
    citiesRegistration: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async POST_PPDB(
      { commit, dispatch },
      { murid, orangtua, prestasi, beasiswa },
    ) {
      try {
        const {
          data: { uuidRegisPPDB },
        } = await axios(`${config.apiUrl}/api/ppdb`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            murid,
            orangtua,
            prestasi,
            beasiswa,
          },
        })

        return new Promise((resolve) => {
          resolve(uuidRegisPPDB)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_PROVINCES({ commit }, { current }) {
      try {
        // const {
        //   data: { provinsi: data },
        // } = await axios(
        //   'https://dev.farizdotid.com/api/daerahindonesia/provinsi',
        //   {
        //     method: 'get',
        //   },
        // )
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/indonesia/provinces`,
          {
            method: 'get',
          },
        )
        const newData = data.map((dat) => {
          return {
            id: dat.id,
            key: dat.id + '_' + dat.name,
            name: dat.name,
          }
        })
        if (current === 0) {
          // buat form nomor 1, profil peserta didik
          commit('SET_STATE', {
            provinces: newData,
          })
        } else if (current === 3) {
          // buat frm registrasi peserta didik
          commit('SET_STATE', {
            provincesRegistration: newData,
          })
        }
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_CITIES({ commit }, { id, current }) {
      try {
        // const {
        //   data: { kota_kabupaten: data },
        // } = await axios('https://dev.farizdotid.com/api/daerahindonesia/kota', {
        //   method: 'get',
        //   params: {
        //     id_provinsi: id,
        //   },
        // })
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/indonesia/regencies/by-province`, {
          method: 'get',
          params: {
            id_province: id,
          },
        })
        const newData = data.map((dat) => {
          return {
            id: dat.id,
            key: dat.id + '_' + dat.name,
            name: dat.name,
          }
        })

        if (current === 0) {
          // buat form nomor 1, profil peserta didik
          commit('SET_STATE', {
            cities: newData,
          })
        } else if (current === 3) {
          // buat frm registrasi peserta didik
          commit('SET_STATE', {
            citiesRegistration: newData,
          })
        }
        // console.log(data)
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_DISTRICTS({ commit }, { id }) {
      try {
        // const {
        //   data: { kecamatan: data },
        // } = await axios(
        //   'https://dev.farizdotid.com/api/daerahindonesia/kecamatan',
        //   {
        //     method: 'get',
        //     params: {
        //       id_kota: id,
        //     },
        //   },
        // )
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/indonesia/districts/by-regency`, {
          method: 'get',
          params: {
            id_regency: id,
          },
        })
        const newData = data.map((dat) => {
          return {
            id: dat.id,
            key: dat.id + '_' + dat.name,
            name: dat.name,
          }
        })
        commit('SET_STATE', {
          districts: newData,
        })
        // console.log(data)
        return new Promise((resolve) => resolve(data))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_VILLAGES({ commit }, { id }) {
      try {
        // const {
        //   data: { kelurahan: data },
        // } = await axios(
        //   'https://dev.farizdotid.com/api/daerahindonesia/kelurahan',
        //   {
        //     method: 'get',
        //     params: {
        //       id_kecamatan: id,
        //     },
        //   },
        // )
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/indonesia/villages/by-district`, {
          method: 'get',
          params: {
            id_district: id,
          },
        })
        const newData = data.map((dat) => {
          return {
            id: dat.id,
            key: dat.id + '_' + dat.name,
            name: dat.name,
          }
        })
        commit('SET_STATE', {
          villages: newData,
        })
        // console.log(data)
        return new Promise((resolve) => resolve(data))
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async CHECK_IS_SUCCESS({ commit }, uuid) {
      try {
        await axios(`${config.apiUrl}/api/ppdb/status-ppdb`, {
          method: 'post',
          params: {
            uuid,
          },
        })
        // console.log(data)
        return new Promise((resolve) => resolve())
      } catch (err) {
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async FETCH_STUDENT_CANDIDATE(
      { commit },
      { page, order, search, sortBy, statusPPDB },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/ppdb`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
            statusPPDB,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_DETAIL_STUDENT_CANDIDATE({ commit }, { id }) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/ppdb/${id}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async GET_PPDB_BY_STUDENT_TOKEN({ commit }) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/ppdb/student`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async UPDATE_STUDENT_CANDIDATE_STATUS({ commit }, { payload }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/ppdb/${payload.id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...payload,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_PERSONAL_ANALYTICS({ commit }, { selectedYear }) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/murid/analisis-umum`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: { selectedYear },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_FINANCE_ANALYTICS({ commit }, { selectedYear }) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/murid/analisis-kondisi-keuangan`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: { selectedYear },
          },
        )
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_LOCATION_ANALYTICS({ commit }, { selectedYear }) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/murid/analisis-demografi`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: { selectedYear },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_ACHIEVEMENT_ANALYTICS({ commit }, { selectedYear }) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/murid/analisis-prestasi-beasiswa`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: { selectedYear },
          },
        )
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_ALL_DATA_BY_STATUS({ commit }, { statusPpdb }) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(
          `${config.apiUrl}/api/ppdb/export/${statusPpdb}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
  },
}
