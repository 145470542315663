import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    teachers: [],
    teacher: {},
    students: [],
    student: {},
    classes: [],
    class: {},
    mapels: [],
    waliKelas: [],
    tahunAjar: [],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async UPDATE_STATUS_TAHUN_AJARAN({ commit, dispatch, state }, { id }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/tahun_ajaran/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data.flag)
          }, 500)
        })
      } catch (error) {
        console.log(error)
      }
    },
    FETCH_TEACHERS({ commit, dispatch, state }, { page, order, search, role }) {
      axios(`${config.apiUrl}/api/admin/all-guru`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          order,
          search,
          page,
          role,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            teachers: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.teachers)
        }, 500)
      })
    },
    FETCH_TEACHERS_BY_ID({ commit, dispatch, state }, { id }) {
      axios(`${config.apiUrl}/api/admin/guru/${id}`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            teacher: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.teacher)
        }, 500)
      })
    },
    ADD_TEACHER({ commit, dispatch, state }, { data, idMapel }) {
      let status
      // console.log(data)
      axios(`${config.apiUrl}/api/admin/guru`, {
        method: 'post',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          nik: data.nik,
          nama: data.nama,
          jenis_kelamin: data.jenis_kelamin,
          password: data.nik,
          role: '["guruMapel"]',
          id_mata_pelajaran: idMapel,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          status = err.response.data.message
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    UPDATE_TEACHER({ commit, dispatch, state }, { data }) {
      let status
      axios(`${config.apiUrl}/api/admin/guru/${data.id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          nik: data.NIK,
          nama: data.nama,
          jenis_kelamin: data.jenis_kelamin,
          email: data.email,
          alamat: data.alamat,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    DELETE_TEACHER({ commit, dispatch, state }, { id }) {
      let status
      axios(`${config.apiUrl}/api/guru/${id}`, {
        method: 'delete',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    UPDATE_TEACHER_PASSWORD({ commit, dispatch, state }, { id, data }) {
      let status
      axios(`${config.apiUrl}/api/admin/guru/change_password/${id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          password: data,
        },
      })
        .then((data) => {
          status = data.status
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    FETCH_CLASSES({ commit, dispatch, state }, { page, order, search }) {
      axios(`${config.apiUrl}/api/admin/all-kelas`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          order,
          search,
          page,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            classes: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.classes)
        }, 500)
      })
    },
    async FETCH_CLASS({ commit, dispatch, state }) {
      const { data } = await axios(`${config.apiUrl}/api/kelas`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
      // console.log(data.data)
      commit('SET_STATE', {
        classes: data.data,
      })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.classes)
        }, 500)
      })
    },
    FETCH_CLASS_BY_ID({ commit, dispatch, state }, { id }) {
      axios(`${config.apiUrl}/api/admin/kelas/${id}`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data.kelas.data)
          commit('SET_STATE', {
            class: data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.class)
        }, 500)
      })
    },
    FETCH_WALI_KELAS({ commit, dispatch, state }) {
      axios(`${config.apiUrl}/api/admin/kelas/guru-available`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data)
          commit('SET_STATE', {
            waliKelas: data.available,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.waliKelas)
        }, 500)
      })
    },
    FETCH_TAHUN_AJARAN({ commit, dispatch, state }) {
      axios(`${config.apiUrl}/api/tahun_ajaran`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit('SET_STATE', {
            tahunAjar: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.tahunAjar)
        }, 500)
      })
    },
    UPDATE_CLASS({ commit, dispatch, state }, { data }) {
      let status
      axios(`${config.apiUrl}/api/admin/kelas/${data.id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          id_guru: data.id_guru,
          id_tahun_ajaran: data.id_tahun_ajaran,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    FETCH_MAPEL({ commit, dispatch, state }) {
      axios(`${config.apiUrl}/api/mata_pelajaran`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          // console.log(data.data)
          commit('SET_STATE', {
            mapels: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.mapels)
        }, 500)
      })
    },
    FETCH_STUDENTS({ commit, dispatch, state }, { page, kelasId, order, search }) {
      axios(`${config.apiUrl}/api/admin/all-murid`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        params: {
          order,
          search,
          page,
          id_kelas: kelasId,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            students: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.students)
        }, 500)
      })
    },
    FETCH_STUDENTS_BY_ID({ commit, dispatch, state }, { id }) {
      axios(`${config.apiUrl}/api/admin/murid/${id}`, {
        method: 'get',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then(({ data }) => {
          commit('SET_STATE', {
            student: data.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(state.student)
        }, 500)
      })
    },
    async ADD_STUDENT({ commit, dispatch, state }, { data }) {
      // console.log(data)
      try {
        const newData = await axios(`${config.apiUrl}/api/admin/murid`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            id_kelas: data.id_kelas,
            tahun_masuk: data.tahun_masuk,
            nis: data.nis,
            nama: data.nama,
            jenis_kelamin: data.jenis_kelamin,
            password: data.nis,
          },
        })
        const status = newData.status
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(status)
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    UPDATE_STUDENT({ commit, dispatch, state }, { data }) {
      let status
      axios(`${config.apiUrl}/api/admin/murid/${data.id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          nis: data.nis,
          nama: data.nama,
          jenis_kelamin: data.jenis_kelamin,
          email: data.email,
          alamat: data.alamat,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    UPDATE_STUDENT_PASSWORD({ commit, dispatch, state }, { id, data }) {
      let status
      axios(`${config.apiUrl}/api/admin/murid/change_password/${id}`, {
        method: 'put',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
        data: {
          password: data,
        },
      })
        .then((data) => {
          status = data.status
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    DELETE_STUDENT({ commit, dispatch, state }, { id }) {
      let status
      axios(`${config.apiUrl}/api/murid/${id}`, {
        method: 'delete',
        headers: {
          'Access-Control-Allow-Origin': '*',
          token: localStorage.token,
        },
      })
        .then((data) => {
          status = data.status
        })
        .catch(err => {
          console.log(err)
        })
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(status)
        }, 500)
      })
    },
    async FETCH_SCHEDULE_TEACHER({ commit, dispatch, state, rootState }, { idGuru, idTahunAjaran }) {
      // const { data: { data: { nama: namaGuru } } } = await axios(`${config.apiUrl}/api/guru/${idGuru}`, {
      //   method: 'get',
      //   headers: {
      //     token: localStorage.token,
      //   },
      //   params: { id_tahun_ajaran: idTahunAjaran },
      // })
      const { data: { data: allSlot } } = await axios(`${config.apiUrl}/api/admin/schedule-teacher`, {
        method: 'get',
        headers: {
          token: localStorage.token,
        },
        params: {
          id_guru: idGuru,
          id_tahun_ajaran: idTahunAjaran,
        },
      })

      // console.log(namaGuru, allSlot)
      return new Promise((resolve, reject) => {
        resolve({ allSlot })
      })
    },
    async FETCH_CLASS_AND_SUBJECT_BY_SLOT({ commit, dispatch, state }, { idGuru, posisi, hari, idTahunAjaran }) {
      try {
        let kelas = []
        let mapel = []
        const { data: { data } } = await axios(`${config.apiUrl}/api/kelas/class-by-slot`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
            posisi,
            hari,
            id_tahun_ajaran: idTahunAjaran,
          },
        })
        kelas = data.kelas
        mapel = data.mapel
        // commit('SET_STATE', {
        //   classes: data.data,
        // })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ kelas, mapel })
          }, 500)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async CREATE_SLOT({ commit, dispatch, state }, { slot, idTahunAjaran }) {
      try {
        await axios(`${config.apiUrl}/api/slot`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            slot,
          },
          params: { id_tahun_ajaran: idTahunAjaran },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async GET_TEACHER_BY_ID_MAPEL({ commit, dispatch, state }, { idMapel, idTahunAjaran }) {
      try {
        // console.log(idMapel)
        const { data: { data: guru } } = await axios(`${config.apiUrl}/api/admin/teacher-by-id-mapel`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_tahun_ajaran: idTahunAjaran,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(guru)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_SLOT({ commit, dispatch, state }, { idSlot, slot }) {
      try {
        await axios(`${config.apiUrl}/api/admin/update-slot/${idSlot}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            slot,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
      }
    },
    async DELETE_SLOT({ commit, dispatch, state }, { idSlot, idTahunAjaran }) {
      try {
        await axios(`${config.apiUrl}/api/slot/hard_delete/${idSlot}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: { id_tahun_ajaran: idTahunAjaran },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
      }
    },
    async VALIDATION_CLASS_SLOT({ commit, dispatch, state }, payload) {
      try {
        // console.log(tipeModelJadwal, jamMulai, posisi, hari, idKelas)
        const { data: { data } } = await axios(`${config.apiUrl}/api/admin/validation/class-and-slot`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: payload,
        })

        return new Promise((resolve, reject) => {
          resolve(data.isValidate)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async VALIDATE_EDIT_SLOT({ commit, dispatch, state }, payload) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/admin/validation/edit-slot`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: payload,
        })

        // console.log(data)

        return new Promise((resolve, reject) => {
          resolve(data.isValidate)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async EDIT_PAYMENT_STATUS({ commit, dispatch, state }, { status, idMurid }) {
      try {
        await axios(`${config.apiUrl}/api/admin/status/murid/${idMurid}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            status,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_ALL_REWARD_PUNISHMENT({ commit, dispatch, state }, { page, order, sortBy, search, type }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/reward_punishment`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            page,
            order,
            sortBy,
            search,
            type,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(data.data)
          }, 500)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_REWARD_PUNISHMENT({ commit, dispatch, state }, payload) {
      try {
        let isSuccess = false
        const { dataRewardPunishment } = payload
        await axios(`${config.apiUrl}/api/reward_punishment/create`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            ...dataRewardPunishment,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve(isSuccess)
        })
      } catch (error) {
        console.log('error', error)
      }
    },
    async EDIT_REWARD_PUNISHMENT({ commit, dispatch, state }, payload) {
      try {
        let isSuccess = false
        const { id, dataRewardPunishment } = payload
        await axios(`${config.apiUrl}/api/reward_punishment/update/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            ...dataRewardPunishment,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve(isSuccess)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async DELETE_REWARD_PUNISHMENT({ commit, dispatch, state }, { id }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/reward_punishment/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve(isSuccess)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_ALL_EXTRACURRICULAR({ commit, dispatch, state }, { page, order, sortBy, search, type }) {
      try {
        // console.log(idTahunAjaran, 'masuk')
        const { data } = await axios(`${config.apiUrl}/api/ekstrakurikuler`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            page,
            order,
            sortBy,
            search,
            type,
            // id_tahun_ajaran: idTahunAjaran,
          },
        })
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve({ ekstrakurikuler: data.data, total: data.total })
          }, 500)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async UPDATE_EXTRACURRICULAR({ commit, dispatch, state }, { data }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/ekstrakurikuler/update_all_extracurricular`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            dataTable: data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async UPDATE_EXTRACURRICULAR_DETAIL({ commit, dispatch, state }, { data, id }) {
      try {
        let isSuccess = false
        await axios(`${config.apiUrl}/api/ekstrakurikuler/update_all_extracurricular_detail${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            data,
          },
        })
        return new Promise((resolve, reject) => {
          isSuccess = true
          resolve({
            isSuccess,
            dataTable: data,
          })
        })
      } catch (err) {
        console.log(err)
      }
    },
    async FETCH_EKSKUL_BY_ID({ commit, dispatch, state }, { id }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/ekstrakurikuler/${id}`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },

    async EDIT_EKSKUL_GENERAL_DATA({ commit, dispatch, state }, { idEkskul, savedData, daftarLevel }) {
      try {
        await axios(`${config.apiUrl}/api/ekstrakurikuler/` + idEkskul, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            saved_data: savedData,
            daftar_level: daftarLevel,
          },
        })

        return new Promise(resolve => resolve(true))
      } catch (error) { console.log(error) }
    },
    async EDIT_EXTRACURRICULAR({ commit, dispatch, state }, { idEkskul, savedData }) {
      try {
        await axios(`${config.apiUrl}/api/ekstrakurikuler/` + idEkskul, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            saved_data: savedData,
          },
        })

        return new Promise(resolve => resolve(true))
      } catch (error) { console.log(error) }
    },

    async FETCH_TEACHER_NOT_EXTRACURRICULAR_SUPERVISOR_LIST({ commit, dispatch, state }, { idTahunAjar, idCurrentSupervisor }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/admin/guru/not-spv-extracurricular`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: { id_tahun_ajaran: idTahunAjar, id_current_supervisor: idCurrentSupervisor },
        })
        return new Promise(resolve => resolve(data))
      } catch (error) { console.log(error) }
    },

    async CREATE_EXTRACURRICULAR({ commit, dispatch, state }, {
      newExtracurricular,
      // idPembina, daftarLevel
    }) {
      try {
        // console.log(newExtracurricular, daftarLevel)
        const { data: { data } } = await axios(`${config.apiUrl}/api/ekstrakurikuler/`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            data: newExtracurricular,
            // id_pembina: idPembina,
            // daftar_level: daftarLevel,
          },
        })
        return new Promise(resolve => resolve(data))
      } catch (error) { console.log(error) }
    },
    async DELETE_EXTRACURRICULAR({ commit, dispatch, state }, { idEkskul }) {
      try {
        await axios(`${config.apiUrl}/api/ekstrakurikuler/` + idEkskul, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise(resolve => resolve(true))
      } catch (error) { console.log(error) }
    },
    async GET_ALL_MAPEL({ commit, dispatch, state }, { idEkskul }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/mata-pelajaran/`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise(resolve => resolve(data))
      } catch (error) { console.log(error) }
    },
    async EDIT_CURRENT_SUPERVISOR_OF_EXTRACURRICULAR({ commit, dispatch, state }, { idPembina, idEkskul, idTahunAjar }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/ekstrakurikuler/` + idEkskul + '/pembina-saat-ini', {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_pembina: idPembina,
            id_tahun_ajaran: idTahunAjar,
          },
        })
        return new Promise(resolve => resolve(data))
      } catch (error) { console.log(error) }
    },
    async GET_BLOCKED_ACCOUNT({ commit, dispatch, state }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/admin/rate-limiter/`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise(resolve => resolve(data))
      } catch (error) { console.log(error) }
    },
    async DELETE_BLOCKED_ACCOUNT({ commit, dispatch, state }, { key }) {
      try {
        // console.log('key :>> ', key)
        await axios(`${config.apiUrl}/api/admin/rate-limiter/${key}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise(resolve => resolve(true))
      } catch (error) { console.log(error) }
    },
    async UPDATE_ROLE({ commit, dispatch, state }, { id, roles }) {
      try {
        await axios(`${config.apiUrl}/api/admin/guru/${id}/role`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            roles,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async REMOVE_ROLE({ commit, dispatch, state }, { id, roles }) {
      try {
        await axios(`${config.apiUrl}/api/admin/guru/${id}/role`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            roles,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async FETCH_TEMPLATE({ commit, dispatch, state }, { idMapel, idLevel, type }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/template_report`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_level: idLevel,
            type,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        console.log(err)
      }
    },
    async POST_TEMPLATE({ commit, dispatch, state }, { dataTemplate }) {
      try {
        console.log(dataTemplate)
        await axios(`${config.apiUrl}/api/template_report`, {
          method: 'post',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            ...dataTemplate,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async EDIT_TEMPLATE({ commit, dispatch, state }, { id, dataTemplate }) {
      try {
        await axios(`${config.apiUrl}/api/template_report/${id}`, {
          method: 'put',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          data: {
            ...dataTemplate,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async DELETE_TEMPLATE({ commit, dispatch, state }, { id }) {
      try {
        await axios(`${config.apiUrl}/api/template_report/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async FETCH_ALL_EXTRACURRICULLAR_ON_ACADEMIC_YEAR({ commit, dispatch, state }, { id, page, order, sortBy, search }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/ekstrakurikuler/tahun-ajaran/${id}`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: { page, order, sortBy, search },
        })
        return new Promise((resolve, reject) => {
          resolve({ data: data.data, total: data.total })
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          resolve(false)
        })
      }
    },
    async FETCH_MODEL_MASTER({ commit, dispatch, state }, { page, order, sortBy }) {
      try {
        const { data: { data, total } } = await axios(`${config.apiUrl}/api/model`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: { page, order, sortBy },
        })
        return new Promise(resolve => resolve({ data, total }))
      } catch (error) { console.log(error) }
    },
    async CREATE_MODEL({ commit, dispatch, state }, payload) {
      try {
        // console.log(newExtracurricular, daftarLevel)
        const { data: { data } } = await axios(`${config.apiUrl}/api/model/`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: payload,
        })
        return new Promise(resolve => resolve(data))
      } catch (error) { console.log(error) }
    },
    async UPDATE_MODEL({ commit, dispatch, state }, {
      newData, id,
      // idPembina, daftarLevel
    }) {
      try {
        // console.log(newExtracurricular, daftarLevel)
        const { data: { data } } = await axios(`${config.apiUrl}/api/model/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: newData,
        })
        return new Promise(resolve => resolve(data))
      } catch (error) { console.log(error) }
    },
    async DELETE_MODEL({ commit, dispatch, state }, { id }) {
      try {
        await axios(`${config.apiUrl}/api/model/${id}`, {
          method: 'delete',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async CHECK_SUBJECT({ commit, dispatch, state }, { idGuru }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/admin/guru/check-subject`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
          params: { id_guru: idGuru },
        })
        return new Promise((resolve, reject) => {
          resolve(Boolean(data.length))
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_TAHUN_MASUK({ commit, dispatch, state }) {
      try {
        const { data: { data, yearsActive } } = await axios(`${config.apiUrl}/api/tahun_ajaran/tahun-masuk`, {
          method: 'get',
          headers: {
            'Access-Control-Allow-Origin': '*',
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve({ data, yearsActive })
        })
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
}
