import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {},
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_HEADER_EXTRACURRICULAR({ commit, dispatch, state }, { idGuru }) {
      try {
        commit('SET_STATE', {
          isLoading: true,
        }, { root: true })
        const { data } = await axios(`${config.apiUrl}/api/ekstrakurikuler/pembina/header/${idGuru}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })

        const header = data.data

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(header)
          })
        })
      } catch (error) {
        commit('SET_STATE', {
          isLoading: false,
        }, { root: true })
        console.log(error)
      }
    },
    async FETCH_LIST_PESERTA_EXTRACURRICULAR({ commit, dispatch, state }, { idGuru, page, order, search }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/ekstrakurikuler/pembina/peserta/${idGuru}`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            order,
            search,
            page,
          },
        })

        const ListPeserta = data.data

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(ListPeserta)
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async PUT_GRADING_EXTRACURRICULAR({ commit, dispatch, state }, { savedData }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/ekstrakurikuler/pembina/peserta`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            data: savedData,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(isSuccess)
          })
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve(isSuccess)
          })
        })
      }
    },
    async FETCH_GENERAL_DATA_PEMBINA_BY_ID_PEMBINA({ commit, dispatch, state }, { idGuru }) {
      try {
        const { data: { data: generalData, tahun_ajaran: tahunAjaran } } = await axios(`${config.apiUrl}/api/ekstrakurikuler/pembina/general`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_guru: idGuru,
          },
        })

        const { data: dataKelas } = await dispatch('FETCH_DATA_KELAS')
        return new Promise((resolve) => {
          resolve({
            generalData,
            dataKelas,
            tahunAjaran,
          })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_DATA_KELAS({ commit, dispatch, state }) {
      try {
        const { data } = await axios(`${config.apiUrl}/api/kelas`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return data
      } catch (error) {
        console.log(error)
      }
    },
    async GET_MURID_BY_ID_KELAS({ commit, dispatch, state }, { idKelas }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/kelas/` + idKelas + '/murid', {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise(resolve => resolve(data))
      } catch (error) {
        console.log(error)
      }
    },
    async GET_EXTRACURRICULAR_PARTICIPANTS_BY_ID_EKSKUL({ commit, dispatch, state }, { idEkskul }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/ekstrakurikuler/` + idEkskul + '/peserta', {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise(resolve => resolve(data))
      } catch (error) { console.log(error) }
    },
    async ADD_NEW_PARTICIPANTS_BY_ID_EKSKUL({ commit, dispatch, state }, { idEkskul, savedNewParticipantsData }) {
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/ekstrakurikuler/` + idEkskul + '/peserta', {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            new_participants: savedNewParticipantsData,
          },
        })

        return new Promise(resolve => resolve(data))
      } catch (error) { console.log(error) }
    },

    async REMOVE_STUDENTS_BY_ID_EKSKUL({ commit, dispatch, state }, { idEkskul, removedStudentsData }) {
      try {
        await axios(`${config.apiUrl}/api/ekstrakurikuler/` + idEkskul + '/peserta', {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
          data: {
            removed_students_data: removedStudentsData,
          },
        })

        return new Promise(resolve => resolve(true))
      } catch (error) { console.log(error) }
    },
  },
}
