import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from '@/config'
import setPrimaryColor from '@/helpers/setPrimaryColor'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    // institusi: {
    //   nama: null,
    //   logoPreview: null,
    //   logo: null,
    //   email: null,
    //   tipe_institusi: null,
    //   no_hp: null,
    //   fax: null,
    //   alamat: null,
    //   logoUpload: null,
    // },
    institusi: JSON.parse(localStorage.getItem('institusi')) || null,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_PROGRAM({ commit }, { page, order, search, sortBy }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/program`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const program = data.data

        return new Promise((resolve, reject) => {
          resolve(program)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_PROGRAM({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataProgram } = payload
        await axios(`${config.apiUrl}/api/program`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataProgram,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_PROGRAM({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataProgram } = payload
        await axios(`${config.apiUrl}/api/program/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataProgram,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_PROGRAM({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/program/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_SUBJECT({ commit }, { page, order, sortBy, filterBy, filterValue }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/mata_pelajaran/admin/read`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              page,
              order,
              sortBy,
              filterBy,
              filterValue,
            },
          },
        )
        const subject = data.data

        return new Promise((resolve, reject) => {
          resolve(subject)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_CLASS({ commit }, { page, order, search, sortBy }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/kelas/master`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const dataClass = data.data

        return new Promise((resolve, reject) => {
          resolve(dataClass)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_CLASS({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataClass } = payload
        await axios(`${config.apiUrl}/api/kelas`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataClass,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_CLASS({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataClass } = payload
        await axios(`${config.apiUrl}/api/kelas/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataClass,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_CLASS({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/kelas/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_STUDENT({ commit }, { page, order, search, sortBy }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/murid`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const dataStudent = data.data

        return new Promise((resolve, reject) => {
          resolve(dataStudent)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_SUBJECT_BY_ID({ commit }, payload) {
      // console.log(axios.defaults)
      try {
        const { id } = payload
        const { data } = await axios(
          `${config.apiUrl}/api/mata_pelajaran/admin/read/${id}`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
          },
        )
        const subject = data.data

        return new Promise((resolve, reject) => {
          resolve(subject)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_SUBJECT({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataSubject } = payload
        await axios(`${config.apiUrl}/api/mata_pelajaran/admin/create`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataSubject,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async POST_STUDENT({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataStudent } = payload
        await axios(`${config.apiUrl}/api/murid`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            students: dataStudent,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_STUDENT({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataStudent } = payload
        await axios(`${config.apiUrl}/api/murid/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataStudent,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_SUBJECT({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataSubject } = payload
        await axios(`${config.apiUrl}/api/mata_pelajaran/admin/update/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataSubject,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async UPDATE_PASSWORD_STUDENT({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, password } = payload
        await axios(`${config.apiUrl}/api/admin/murid/change_password/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            password,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_SUBJECT({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/mata_pelajaran/admin/delete/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_STUDENT({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/murid/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_SCHOOL_YEAR({ commit }, { page, order, search, sortBy, tipe }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/tahun_ajaran`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
            tipe,
          },
        })

        return new Promise((resolve, reject) => {
          resolve({ data: data.data, total: data.total })
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_DUPLICATE_FROM_FIRST_SEMESTER({ commit }, idTahunAjaran) {
      let isSuccess = false
      try {
        await axios(
          `${config.apiUrl}/api/tahun_ajaran/duplicate-from-first-semester/${idTahunAjaran}`,
          {
            method: 'post',
            headers: {
              token: localStorage.token,
            },
          },
        )
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve({ isSuccess })
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async POST_SCHOOL_YEAR({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataSchoolYear } = payload
        const { data } = await axios(`${config.apiUrl}/api/tahun_ajaran`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataSchoolYear,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve({ isSuccess, data })
        })
      } catch (error) {
        console.log(error.errors)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async EDIT_SCHOOL_YEAR({ commit }, payload) {
      try {
        const { id, dataSchoolYear } = payload
        await axios(`${config.apiUrl}/api/tahun_ajaran/update/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataSchoolYear,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(true)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async DELETE_SCHOOL_YEAR({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/tahun_ajaran/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_HOMEROOM_TEACHER_AVALAIBLE({ commit }, { id }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/admin/kelas/guru-available`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              id_tahun_ajaran: id,
            },
          },
        )
        const dataTeacher = data.available

        return new Promise((resolve, reject) => {
          resolve(dataTeacher)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_TEACHER({ commit }, { page, order, search, sortBy }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/guru`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const dataTeacher = data.data

        return new Promise((resolve, reject) => {
          resolve(dataTeacher)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_TEACHER({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataTeacher } = payload
        await axios(`${config.apiUrl}/api/guru`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            teachers: dataTeacher,
          },
        })
        isSuccess = true
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_TEACHER({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataTeacher } = payload
        await axios(`${config.apiUrl}/api/guru/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataTeacher,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async UPDATE_PASSWORD_TEACHER({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, password } = payload
        await axios(`${config.apiUrl}/api/admin/guru/change_password/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            password,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_TEACHER({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/guru/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_LEVEL({ commit }, { page, order, search, sortBy }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/level`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        const level = data.data

        return new Promise((resolve, reject) => {
          resolve(level)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_LEVEL({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataLevel } = payload
        await axios(`${config.apiUrl}/api/level`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataLevel,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_LEVEL({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataLevel } = payload
        await axios(`${config.apiUrl}/api/level/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataLevel,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_LEVEL({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/level/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_INSTITUSI({ commit }) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/institusi`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        const institusi = {
          nama: data.nama,
          logo: data.logo,
          email: data.email,
          tipe_institusi: data.tipe_institusi,
          no_hp: data.no_hp,
          fax: data.fax,
          alamat: data.alamat,
          primary_color: data.primary_color,
          video_conference: data.video_conference,
          template_report_description: data.template_report_description,
          jumlah_pilihan_ganda: data.jumlah_pilihan_ganda,
          presence_setting: data.presence_setting,
          is_announcement: data.is_announcement,
        }
        setPrimaryColor(data.primary_color)

        commit('SET_STATE', {
          institusi,
        })

        localStorage.setItem('institusi', JSON.stringify(institusi))

        return new Promise((resolve, reject) => {
          resolve(institusi)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_GROUP({ commit }, { page, order, search, sortBy }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/kelompok`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_GROUP({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataGroup } = payload
        await axios(`${config.apiUrl}/api/kelompok`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataGroup,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_GROUP({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataGroup } = payload
        await axios(`${config.apiUrl}/api/kelompok/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataGroup,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_GROUP({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/kelompok/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_TEACHER_LIST() {
      try {
        const { data } = await axios(`${config.apiUrl}/api/guru/id-nama`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async FETCH_ALL_PMA({ commit }, { page, order, search, sortBy, jabatan }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(
          `${config.apiUrl}/api/periode-manajemen-atas`,
          {
            method: 'get',
            headers: {
              token: localStorage.token,
            },
            params: {
              page,
              order,
              search,
              sortBy,
              jabatan: jabatan === 'all' ? null : jabatan,
            },
          },
        )
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_PMA({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataPMA } = payload
        await axios(`${config.apiUrl}/api/periode-manajemen-atas`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataPMA,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_PMA({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataPMA } = payload
        await axios(`${config.apiUrl}/api/periode-manajemen-atas/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataPMA,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_PMA({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/periode-manajemen-atas/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_ALL_CORE_COMPETENCE(
      { commit },
      { page, order, search, sortBy },
    ) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/kompetensi-inti`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
            order,
            search,
            sortBy,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async POST_CORE_COMPETENCE({ commit }, payload) {
      let isSuccess = false
      try {
        const { dataCoreCompetence } = payload
        await axios(`${config.apiUrl}/api/kompetensi-inti`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataCoreCompetence,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async EDIT_CORE_COMPETENCE({ commit }, payload) {
      let isSuccess = false
      try {
        const { id, dataCoreCompetence } = payload
        await axios(`${config.apiUrl}/api/kompetensi-inti/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...dataCoreCompetence,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_CORE_COMPETENCE({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/kompetensi-inti/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async DELETE_TOPIK({ commit }, { id }) {
      let isSuccess = false
      try {
        await axios(`${config.apiUrl}/api/topik/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        isSuccess = true

        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      } catch (error) {
        console.log(error)
        return new Promise((resolve, reject) => {
          resolve(isSuccess)
        })
      }
    },
    async FETCH_KD_MASTER({ commit }, { idMapel, idLevel }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/kd/by`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_level: idLevel,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async POST_KD({ commit }, { dataKd, idMapel, idLevel }) {
      // console.log(axios.defaults)
      try {
        console.log(idMapel, idLevel)
        const { data } = await axios(`${config.apiUrl}/api/kd/`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          params: { id_mata_pelajaran: idMapel, id_level: idLevel },
          data: dataKd,
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async EDIT_KD({ commit }, { dataKd, id }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/kd/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: dataKd,
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async DELETE_KD({ commit }, { id }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/kd/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_RPP_BY({ commit }, { idMapel, idLevel }) {
      // console.log(axios.defaults)
      try {
        const { data } = await axios(`${config.apiUrl}/api/rpp/`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            id_mata_pelajaran: idMapel,
            id_level: idLevel,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async POST_RPP({ commit }, { data: newData, idMapel, idLevel }) {
      // console.log(axios.defaults)
      try {
        const dataRPP = {
          ...newData,
          id_mata_pelajaran: idMapel,
          id_level: idLevel,
        }
        const { data } = await axios(`${config.apiUrl}/api/rpp/`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            dataRPP,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async DELETE_RPP({ commit }, { id }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/rpp/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async EDIT_RPP({ commit }, { data: newData, id }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/rpp/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            dataRPP: newData,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_DETAIL_CANDIDATE({ commit }, { filter, batch, search, page }) {
      try {
        const {
          data: { data, total },
        } = await axios(`${config.apiUrl}/api/admin/murid/ppdb`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            filter,
            tahun_masuk: batch,
            search,
          },
          params: {
            page,
          },
        })

        return new Promise((resolve, reject) => {
          resolve({
            data,
            total,
          })
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async FETCH_LEVELS_AND_CLASSES({ commit }, { idMapel }) {
      // console.log(axios.defaults)
      try {
        const {
          data: { data },
        } = await axios(`${config.apiUrl}/api/level/classes`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: { id_mata_pelajaran: idMapel },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async PUT_STUDENT_PPDB({ commit }, payload) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/ppdb/data/${payload.id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...payload,
          },
        })

        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async CREATE_TOPIC({ commit }, payload) {
      // console.log(axios.defaults)
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/topik`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: {
            ...payload,
          },
        })

        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async FETCH_PREDICATE({ commit }, { page }) {
      // console.log(axios.defaults)
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/predikat`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async PUT_PREDICATE({ commit }, { newData, id }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/predikat/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: newData,
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async POST_PREDICATE({ commit }, { newData }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/predikat`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: newData,
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async DELETE_PREDICATE({ commit }, { id }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/predikat/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async FETCH_PASSING_GRADE({ commit }, { page }) {
      // console.log(axios.defaults)
      try {
        const { data: { data } } = await axios(`${config.apiUrl}/api/kbm-master/interval`, {
          method: 'get',
          headers: {
            token: localStorage.token,
          },
          params: {
            page,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async PUT_PASSING_GRADE({ commit }, { newData, id }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/kbm-master/interval/${id}`, {
          method: 'put',
          headers: {
            token: localStorage.token,
          },
          data: newData,
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async POST_PASSING_GRADE({ commit }, { newData }) {
      // console.log(axios.defaults)
      try {
        await axios(`${config.apiUrl}/api/kbm-master/interval`, {
          method: 'post',
          headers: {
            token: localStorage.token,
          },
          data: newData,
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
    async DELETE_PASSING_GRADE({ commit }, { id }) {
      try {
        await axios(`${config.apiUrl}/api/kbm-master/${id}`, {
          method: 'delete',
          headers: {
            token: localStorage.token,
          },
        })
        return new Promise((resolve, reject) => {
          resolve()
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(new Error(error))
        })
      }
    },
  },
}
